exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-services-adults-js": () => import("./../../../src/pages/services/adults.js" /* webpackChunkName: "component---src-pages-services-adults-js" */),
  "component---src-pages-services-children-js": () => import("./../../../src/pages/services/children.js" /* webpackChunkName: "component---src-pages-services-children-js" */),
  "component---src-pages-services-gender-affirming-group-acknowledgement-js": () => import("./../../../src/pages/services/gender-affirming-group-acknowledgement.js" /* webpackChunkName: "component---src-pages-services-gender-affirming-group-acknowledgement-js" */),
  "component---src-pages-services-gender-affirming-group-form-js": () => import("./../../../src/pages/services/gender-affirming-group-form.js" /* webpackChunkName: "component---src-pages-services-gender-affirming-group-form-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-temp-js": () => import("./../../../src/pages/services-temp.js" /* webpackChunkName: "component---src-pages-services-temp-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

